<template>
    <div id="Container">
        <div class="etc_modulo_container">
            <img src="../assets/website/global/logo_comparte.svg" alt="Comparte" class="etc_modulo_logo">
            <div class="etc_modulo_descripcion">
                <div class="etc_modulo_title">¿Por qué hacer parte del Cambio?</div>
                <div class="etc_modulo_parrafo">
                    <p>
                        Cada vez que alguien comparte Fake News o información falsa, está propiciando un espacio en donde otras personas podrían consumir, creer y seguir difundiendo esta información.
                    </p>
                    <br>
                    <p>
                        Tu puedes ayudar a romper esta cadena de información falsa mediante tus hábitos de consumo; aplica lo aprendido aquí y evalua toda la información antes de compartirla y si algún conocido está difundiendo este tipo de noticias, hazle saber que está cayendo en este fenómeno.
                    </p>
                </div>
            </div>
        </div>

        <div class="botonazul borderblue botonarriba">Galeria de Memes</div>
        <div class="etc_main_container_memes">
            <div id="splide" v-masonry="containerId" transition-duration="0.3s" item-selector=".imagen_carrusel" column-width="80" fit-width="true">
                <div v-masonry-tile class="item" v-for="n in 20" :key="n">
                    <img class="imagen_carrusel" :src="require('../assets/website/memes/MemeETC ('+n+').jpeg')">
                </div>
            </div>
        </div>

        <div class="titulo_azul">¿Cómo puedo ayudar?</div>
        <div class="parrafo">
            Los memes son una de las maneras más efectivas en la actualidad para comunicar un mensaje con un tono humóristico. A continuación, encontrarás una galeria de memes que puedes compartir con tus amigos o conocidos cuando estén compartiendo información falsa en sus redes.
        </div>
        <div class="titulo_azul last">#EntreTantoCuento</div>
    </div>
</template>

<style scoped>
.etc_main_container_memes {
  background-color: #0B132E;
  background-image: url('../assets/website/global/patron_triangulos.svg');
  background-size: cover;
  background-position: center;

  width: 100%;

  padding: 70px 30px;
}

#splide {
    margin: 0px auto;
    width: 80%;
}
.imagen_carrusel {
    width: 300px;
    object-fit: contain;
    border-radius: 15px;
    margin-bottom: 20px;
}
.is-active > img {
    height: 42vh;
    width: 420px;
}

.titulo_azul {
    font: 48px "Bebas Neue";
    color: #54ceea;
    margin: 60px 0px 10px;
}
.parrafo {
    width: 60%;
    max-width: 800px;
    padding: 20px;
    margin: 0px auto;
}
.last {
    margin-bottom: 250px;
}

@media screen and (max-width: 900px) {
    .imagen_carrusel {
        width: 250px;
    }
    .is-active > img {
        width: 300px;
    }

    .parrafo {
        width: 80%;
        padding: 20px 0px;
    }
}
</style>
